import isMobile from 'ismobilejs';

const controlPageScroll = (allow = true) => {
  if (parent && !isMobile(window.navigator).any) {
    return;
  }

  if (allow) {
    parent.document.body.setAttribute('style', 'overflow: auto;');
  } else {
    parent.document.body.setAttribute(
      'style',
      'overflow: hidden !important; position: fixed !important; height: 0 !important; margin: 0!important;',
    );
  }
};

export default controlPageScroll;
