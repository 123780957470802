if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'redux-zero/react';
import store from './store/';

function Widget() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
}
// TODO: check why rerenders 2 times this file
let container = document.getElementById('chatim-container');

if (!container) {
  container = document.createElement('div');
  container.setAttribute('id', 'chatim-container');
  if (window.chatimSettings.demo) {
    container = document.getElementById('chatim-container');
  } else {
    document.body.appendChild(container);
  }
}

// let container = document.getElementById("chatim-container");

ReactDOM.render(<Widget />, container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
