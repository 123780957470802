const set = (name, value) => {
  const chatId = localStorage.getItem('chatim-chat-id');
  if (!chatId) {
    return null;
  }
  let currentSettings = JSON.parse(localStorage.getItem(`chatim-${chatId}`));
  let newSettings;
  if (!currentSettings) {
    newSettings = {
      [name]: value,
    };
  } else {
    newSettings = Object.assign({}, currentSettings, { [name]: value });
  }
  localStorage.setItem(`chatim-${chatId}`, JSON.stringify(newSettings));
};

const get = (name, defaultValue = null) => {
  const chatUuid = localStorage.getItem('chatim-chat-uuid');
  let currentSettings = JSON.parse(localStorage.getItem(`chatim-${chatUuid}`));
  return currentSettings && currentSettings[name] ? currentSettings[name] : defaultValue;
};

const chatId = () => {
  return localStorage.getItem('chatim-widget-uuid');
};

const clear = () => {
  const chatUuid = localStorage.getItem('chatim-chat-uuid');
  localStorage.removeItem('chatim-chat-uuid');
  localStorage.removeItem(`chatim-${chatUuid}`);
};

const clearClientUuid = () => {
  localStorage.removeItem('chatim-client-uuid');
};

const init = (data) => {
  if (!data || !data.widgetUuid) {
    return;
  }

  localStorage.setItem('chatim-widget-uuid', data.widgetUuid);
  let config = data.widgetConfig;
  let storageLifeHours = config.storageLife ? config.storageLife : 24;
  let storageInitDate = get('initDate');
  if (!storageInitDate) {
    set('initDate', new Date());
    set('displayStatus', 'closed');
    set('stepsHistory', [startId]);
  } else {
    let timePassed = new Date() - new Date(storageInitDate);

    if (config.storageLife !== 0 && timePassed / 1000 / 60 / 60 >= storageLifeHours) {
      clear();
      set('initDate', new Date());
      set('displayStatus', 'closed');
    }
  }
};

export default {
  set,
  get,
  init,
  chatId,
  clear,
  clearClientUuid,
};
