import storage from '../../lib/storage';
import controlScroll from '../../lib/controlScroll';

const actions = (store) => ({
  openWidget: (state) => {
    storage.set('widgetDisplayStatus', 'closed');
    storage.set('widgetWasOpenedBefore', true);
    storage.set('helloMsgWasShown', true);
    controlScroll(true);
    return {
      widgetDisplayStatus: 'opened',
      widgetWasOpenedBefore: true,
      helloMsgWasShown: true,
      showHelloMsg: false,
    };
  },
  closeWidget: (state) => {
    storage.set('widgetDisplayStatus', 'closed');
    return {
      widgetDisplayStatus: 'closed',
    };
  },
  showDropdownClick: (state) => {
    storage.set('showDropdown', true);
    return {
      showDropdown: true,
    };
  },
  hideDropdownClick: (state) => {
    storage.set('showDropdown', false);
    return {
      showDropdown: false,
    };
  },
  hideChat: (state) => {
    storage.set('hiddenStart', new Date().getTime());
    return {
      hidden: true,
    };
  },
  resetChat: (state) => {
    storage.clear();
    return {
      formsData: [],
      chat: [],
      userInputs: {},
      outputsSelected: {},
    };
  },
});

export default actions;
