import React, { Fragment } from 'react';
import { useErrorBoundary } from 'preact/hooks';
import { Global } from '@emotion/core';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { connect } from 'redux-zero/react';
import { Suspense, lazy } from 'preact/compat';
const WidgetOpened = lazy(() => import('./WidgetOpened/WidgetOpened'));
const WidgetClosed = lazy(() => import('./WidgetClosed'));
import Loading from './Loading';

const mapToProps = ({ widgetDisplayStatus }) => ({ widgetDisplayStatus });

const Widget = ({ widgetDisplayStatus }) => {
  const [error] = useErrorBoundary();

  if (error) {
    // Todo add code for errors here. crash if error and close widget
    return <div />;
  }

  return (
    <Fragment>
      <Global
        styles={css`
          input:focus {
            outline: 0;
          }
          @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
          html {
            font-size: 62.5%;
            height: 100%;
          }
          body {
            padding: 0;
            margin: 0;
            font-family: 'Noto Sans', sans-serif;
            font-weight: 400;
            line-height: 2rem;
            font-size: 1.6rem;
            height: 100% !important;
            overflow: hidden;
            height: 100%;
          }
          .frame-root {
            height: 100%;
          }
          .frame-content {
            height: 100%;
          }
        `}
      />

      {widgetDisplayStatus === 'closed' && (
        <Suspense fallback={<div />}>
          <WidgetClosed />
        </Suspense>
      )}
      {widgetDisplayStatus === 'opened' && (
        <Suspense fallback={<div />}>
          <WidgetOpened />
        </Suspense>
      )}
    </Fragment>
  );
};

export default connect(mapToProps)(Widget);
