import socket from './lib/socket';
import store from './store';
import userInfo from './lib/userInfo';

window.chatimWidget = {
  open: () => {
    store.setState({
      widgetDisplayStatus: 'opened',
    });
  },

  close: () => {
    store.setState({
      widgetDisplayStatus: 'closed',
    });
  },

  setWidgetConfig: (widgetConfig) => {
    store.setState({
      widgetConfig,
    });
  },
};
