import React, { useRef } from 'react';
import { connect } from 'redux-zero/react';
import actions from '../store/actions/widgetState';
import weakMemoize from '@emotion/weak-memoize';
import { Global } from '@emotion/core';
import { CacheProvider, keyframes, css } from '@emotion/core';
import createCache from '@emotion/cache';
import styled from '@emotion/styled';
import Frame, { FrameContextConsumer } from 'react-frame-component';

let memoizedCreateCacheWithContainer = weakMemoize((container) => {
  let newCache = createCache({ container });
  return newCache;
});

const Context = (props) => (
  <FrameContextConsumer>
    {({ document }) => {
      return <CacheProvider value={memoizedCreateCacheWithContainer(document.head)}>{props.children}</CacheProvider>;
    }}
  </FrameContextConsumer>
);

const mapToProps = ({ showHelloMsg, widgetConfig }) => ({ showHelloMsg, widgetConfig });

function HelloMsg({ showHelloMsg, widgetConfig, openWidget }) {
  const iframe = useRef(null);
  const container = useRef(null);

  let Container = styled.div`
    z-index: 2147483000;
    width: 260px;
    height: 70px;
    display: ${showHelloMsg ? 'block' : 'none'};
    bottom: ${widgetConfig.ui.bottomMarginPx}px;
    position: ${window.chatimSettings && window.chatimSettings.demo ? '' : 'fixed'};
    ${widgetConfig.ui.position || 'right'}: ${parseInt(widgetConfig.ui.rightMarginPx, 10) + 70}px;
  `;

  const apear = keyframes`
    0% {
      left: 30%;
      opacity: 0;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  `;

  let MsgContainer = styled.div`
    background-color: red;
    max-height: 80px;
    min-height: 3rem;
    font-size: 1.6rem;
    line-height: 1.7rem;
    animation: 450ms ease 0s 1 normal none running ${apear};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    cursor: pointer;
    padding: 1rem 2rem;
    position: relative;
    background-color: ${widgetConfig.ui.colors.main};
    border-radius: 1rem;
    margin-right: 1rem;
    box-shadow: 1px 2px 3px rgba(70, 70, 70, 0.6);
    z-index: 1;
    &:after {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 10px solid ${widgetConfig.ui.colors.main};
      border-right: 10px solid transparent;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      right: -17px;
    }
  `;

  let HelloMsgFrame = styled(Frame)`
    width: 100%;
    height: 100%;
    border-style: none;
  `;

  let HelloMsgWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  return (
    <Container ref={container}>
      <HelloMsgFrame id='chatim-hello-msg-frame' head={<meta charset='UTF-8' />} ref={iframe}>
        <Context>
          <HelloMsgWrapper>
            <Global
              styles={css`
                input:focus {
                  outline: 0;
                }
                @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
                html {
                  font-size: 62.5%;
                  height: 100%;
                }
                body {
                  padding: 0;
                  margin: 0;
                  font-family: 'Noto Sans', sans-serif;
                  font-weight: 500;
                  line-height: 2rem;
                  font-size: 1.6rem;
                  height: 100% !important;
                  overflow: hidden;
                  height: 100%;
                }
                .frame-root {
                  height: 100%;
                }
                .frame-content {
                  height: 100%;
                }
              `}
            />
            <MsgContainer onClick={openWidget}>{widgetConfig.helloMessage.text}</MsgContainer>
          </HelloMsgWrapper>
        </Context>
      </HelloMsgFrame>
    </Container>
  );
}

export default connect(mapToProps, actions)(HelloMsg);
