import createStore from 'redux-zero';
import storage from '../lib/storage';
import controlScroll from '../lib/controlScroll';
import startHelloMsg from '../lib/helloMsgLogic';

let widgetDisplayStatus = window.chatimSettings && window.chatimSettings.demo ? 'opened' : 'closed';

let userUnique = storage.get('userUnique');
if (!userUnique) {
  storage.set('userUnique', false);
}

if (window.chatimSettings.demo) {
  storage.set('widgetDisplayStatus', 'opened');
  storage.set('widgetWasOpenedBefore', true);
  storage.set('helloMsgWasShown', true);
}
widgetDisplayStatus = storage.get('widgetDisplayStatus', widgetDisplayStatus);
if (!window.chatimSettings.demo) {
  controlScroll(widgetDisplayStatus === 'opened' ? false : true);
}
let widgetWasOpenedBefore = storage.get('widgetWasOpenedBefore', false);
let helloMsgWasShown = storage.get('helloMsgWasShown', false);

let hidden = false;
const hiddenStart = parseInt(storage.get('hiddenStart'));
if (hiddenStart && (new Date().getTime() - hiddenStart) / 1000 / 86400 <= 7) {
  hidden = true;
}

const initialState = {
  widgetDisplayStatus,
  widgetWasOpenedBefore, // true or false if widget was automatically opened before already
  chatbotStatus: true,
  showHelloMsg: false,
  showDropdown: false,
  showSmiles: false,
  hidden,
  helloMsgWasShown,
  formsData: [],
  chat: [],
  userInputs: {},
  outputsSelected: {},
};

const store = createStore(initialState);

store.subscribe((data) => {
  if (data && data.widgetConfig && !data.helloMsgWasShown) {
    startHelloMsg(store);
  }
  if (!window.chatimSettings.demo) {
    controlScroll(data.widgetDisplayStatus === 'opened' ? false : true);
  }
});

export default store;
