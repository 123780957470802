let timeout = 0;
let timeoutConfig = 0;
let scrollProcentConfig = 0;
function getDocHeight() {
  var D = parent.document;
  return Math.max(
    D.body.scrollHeight,
    D.documentElement.scrollHeight,
    D.body.offsetHeight,
    D.documentElement.offsetHeight,
    D.body.clientHeight,
    D.documentElement.clientHeight,
  );
}

function amountscrolled() {
  var winheight = parent.window.innerHeight || (parent.document.documentElement || parent.document.body).clientHeight;
  var docheight = getDocHeight();
  var scrollTop =
    parent.window.pageYOffset ||
    (parent.document.documentElement || parent.document.body.parentNode || parent.document.body).scrollTop;
  var trackLength = docheight - winheight;
  var pctScrolled = Math.floor((scrollTop / trackLength) * 100);
  return pctScrolled;
}

const startHelloMsg = (store) => {
  try {
    const triggerHelloMessage = () => {
      if (amountscrolled() >= scrollProcentConfig) {
        showHellowMessage();
        document.removeEventListener('scroll', triggerHelloMessage, true);
      }
    };

    function launchByTimeout() {
      timeout = setTimeout(() => {
        showHellowMessage();
      }, timeoutConfig * 1000);
    }

    const showHellowMessage = () => {
      const { helloMsgWasShown } = store.getState();
      if (!helloMsgWasShown) {
        store.setState({
          showHelloMsg: true,
          helloMsgWasShown: true,
        });
        if (timeout) {
          clearTimeout(timeout);
        }
      } else {
        store.setState({
          helloMsgWasShown: true,
        });
      }
    };

    const { widgetConfig } = store.getState();
    timeoutConfig = widgetConfig.helloMessage.timeout;
    scrollProcentConfig = widgetConfig.helloMessage.scrollProcent;

    if (scrollProcentConfig) {
      document.addEventListener('scroll', triggerHelloMessage, true);
    } else if (timeoutConfig) {
      timeout = launchByTimeout();
    }
  } catch (e) {
    console.log('Error on start hello msg ', e);
  }
};

export default startHelloMsg;
