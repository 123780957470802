import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as LoadingAnimation } from '../svg/loading-new.svg';
import { keyframes } from '@emotion/core';

const loadingKeyframe1 = keyframes`
0% {
  opacity: 0;
}
25% {
  opacity: 1;
}
50% {
  opacity: 0;
}
75% {
  opacity: 0;
}
100% {
  opacity: 0;
}
`;

const loadingKeyframe2 = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;
const loadingKeyframe3 = keyframes`
0% {
  opacity: 0;
}
25% {
  opacity: 0;
}
50% {
  opacity: 0;
}
75% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;

let Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .loading-new_svg__first-circle {
    animation: ${loadingKeyframe1} 1.3s cubic-bezier(0.52, 0.53, 0.8, 0.8) infinite;
  }
  .loading-new_svg__second-circle {
    animation: ${loadingKeyframe2} 1.3s cubic-bezier(0.52, 0.53, 0.8, 0.8) infinite;
  }
  .loading-new_svg__third-circle {
    animation: ${loadingKeyframe3} 1.3s cubic-bezier(0.52, 0.53, 0.8, 0.8) infinite;
  }
`;

function Loading() {
  return (
    <Container>
      <LoadingAnimation />
    </Container>
  );
}

export default Loading;
